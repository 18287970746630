import React from 'react'
import {Container, Row, Col, Form, Button, DropdownButton, Dropdown, Image} from 'react-bootstrap'
import {darkGrey, black, lightGrey, appRed, appBlue, appGold} from "../../styles/color"
import NavBar from "../../component/navBar"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import '../../styles/rc-time-picker/index.css'
import 'react-day-picker/lib/style.css';
import {createOrder, getOrder} from "../../api/reservation";
import {getDateTime, numberWithCommas, toBase64} from "../helper"
import {payOmise, submitSlip} from "../../api/payment";
import {AlertBox, AlertSuccessBox, AlertSlipBox} from "../../component/paymentAlert";
import ImageUploader from 'react-images-upload';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

const liff = window.liff
const {OmiseCard} = window
const override = css`
    display: block;
    margin: 0 auto;
`;

class Payment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      alertFail: false,
      alertSuccess: false,
      alertSlipSuccess: false,
      payWithCreditCard: true,
      name: '',
      tel: '',
      note: '',
      date: '',
      time: '',
      senior_limo: {
        pickup_location: {
          name: ''
        },
        destination_location: {
          name: ''
        },
        vehicle: {
          name: '',
          luggage: '',
          passenger: '',
        }
      },
      estimated_price: 0,
      slip: '',
      slipUploading: false,
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  componentDidMount = async () => {
    console.log(this.props.location.search)
    const orderId = this.props.location.search.split('=')[1] || this.props.location.params.orderId;
    console.log('order:', orderId);

    const res = await getOrder({orderId})
    const {name, tel, note, senior_limo, estimated_price} = res.data
    const {date, time} = getDateTime(senior_limo.pickup_time)
    await this.setState({name, tel, note, senior_limo, date, time, estimated_price})

    OmiseCard.configure({
      publicKey: 'pkey_test_59nrzkxmanuc5bdspw2',
      amount: estimated_price * 100,
    });

    OmiseCard.attach();
  }

  _renderReservationSummary = () => (
    <Container style={styles.reservationSummaryContainer}>
      <Row style={styles.inputRowUpper}>
        <Col xs={1}><FontAwesomeIcon icon="dot-circle" color={appBlue}/></Col>
        <Col xs={7} style={styles.summaryText}>
          {this.state.senior_limo.pickup_location.name}
        </Col>
        <Col xs={3}>
          <Row style={{
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            fontSize: '10px',
            color: darkGrey,
            fontWeight: 'bold'
          }}>
            {/*{this.props.location.params.distance} Km*/}
            {/*12.9 Km*/}
          </Row>
        </Col>
      </Row>
      <Row style={{alignItems: 'center'}}>
        <Col xs={1}><FontAwesomeIcon icon="ellipsis-v" color={darkGrey} style={{marginLeft: '10px'}}/></Col>
        <Col xs={10}>
          <hr/>
        </Col>
      </Row>
      <Row style={styles.inputRowLower}>
        <Col xs={1}><FontAwesomeIcon icon="map-marker-alt" color={appRed}/></Col>
        <Col xs={'auto'} style={styles.summaryText}>
          {this.state.senior_limo.destination_location.name}
        </Col>
      </Row>
      <hr/>
      <Row style={{marginBottom: '15px'}}>
        <Col>
          <FontAwesomeIcon icon="clock"/>
          <p style={styles.summaryTimeText}>
            {this.state.date}
          </p>
          <p style={styles.summaryTimeText}>
            เวลา {this.state.time}
          </p>
        </Col>
      </Row>
    </Container>
  )

  _renderCarSummary = () => (
    <Container style={styles.carSummaryContainer}>
      <Row style={{alignItems: 'center'}}>
        <Col>
          <FontAwesomeIcon icon="car" size='lg'/>
          <p style={styles.summaryCarText}>
            {this.state.senior_limo.vehicle.name}
          </p>
          <FontAwesomeIcon icon="user" style={{marginLeft: '10px'}}/>
          <p style={styles.summaryCarText}>{this.state.senior_limo.vehicle.passenger}</p>
          <FontAwesomeIcon icon="briefcase" style={{marginLeft: '10px'}}/>
          <p style={styles.summaryCarText}>{this.state.senior_limo.vehicle.luggage}</p>
        </Col>
      </Row>
    </Container>
  )

  _renderFareSummary = () => (
    <Container>
      <Row style={{paddingLeft: '20px', paddingRight: '20px'}}>
        <Form style={{width: this.props.width * 0.9, height: '35px'}}>
          <Form.Group controlId="formFareSummary">
            <Form.Row style={{marginTop: '15px'}}>
              <Col xs={7}>
                <Form.Label>ค่าบริการทั้งหมด</Form.Label>
              </Col>
              <Col xs={3}>
                <p style={styles.priceText}>
                  {numberWithCommas(this.state.estimated_price)}
                </p>
              </Col>
              <Col xs={2}>
                <p style={{textAlign: 'right'}}>บาท</p>
              </Col>
            </Form.Row>
          </Form.Group>
        </Form>
      </Row>
    </Container>
  );

  _renderPayment = () => (
    <Form.Group as={Row} style={{paddingLeft: '20px', paddingRight: '20px'}}>
      <Form.Label as="legend" column sm={2} style={{marginBottom: '5px'}}>
        ช่องทางการชำระเงิน
      </Form.Label>
      <Col sm={10}>
        <Form.Check
          style={{marginBottom: '5px'}}
          checked={this.state.payWithCreditCard}
          onChange={() => this.handlePaymentChange({isCreditCard: true})}
          type="radio"
          label="บัตรเครดิต / เดบิต"
          name="formHorizontalRadios"
        />
        <Form.Check
          style={{marginBottom: '2px'}}
          checked={!this.state.payWithCreditCard}
          onChange={() => this.handlePaymentChange({isCreditCard: false})}
          type="radio"
          label="Mobile Banking"
          name="formHorizontalRadios"
        />
      </Col>
      <Form.Label
        style={{paddingLeft: this.props.width * 0.1, fontSize: '12px', color: darkGrey}}>
        888-8-88-888-8 ธนาคารกสิกรไทย<br/>บริษัท Best Life จำกัด
      </Form.Label>
    </Form.Group>
  );

  handlePaymentChange = ({isCreditCard}) => {
    if (isCreditCard) {
      this.setState({payWithCreditCard: true})
    } else {
      this.setState({payWithCreditCard: false})
    }
  };

  alertFailCallback = () => {
    this.setState({alertFail: false});
  };

  alertSuccessCallback = () => {
    this.setState({alertSuccess: false});
    liff.closeWindow()
  };

  _uploadImage = async (file) => {
    const slip = await toBase64(file[file.length-1]);
    this.setState({slip});
  };

  _imageUploader = () => {
    if (!this.state.payWithCreditCard) {
      return (
        <ImageUploader
          singleImage
          withIcon={true}
          buttonText='Choose your slip'
          onChange={this._uploadImage}
          imgExtension={['.jpg', '.png', '.jpeg']}
          maxFileSize={5242880}
        />
      )
    }
  };

  _payment = async () => {
    if (this.state.payWithCreditCard) {
      OmiseCard.open({
        onCreateTokenSuccess: async (token) => {
          console.log('success', token);
          const orderId = this.props.location.search.split('=')[1] || this.props.location.params.orderId;
          const res = await payOmise({token, orderId});
          this.setState({alertSuccess: true})
          // /* Your code, e.g., submit form or send ajax request to server */
        },
        onFormClosed: function () {
          console.log('closed')
          this.setState({alertFail: true})
          // /* Your handler when form was closed */
        },
      })
    } else {
      // check slip not empty
      if (!this.state.slip) {
        this.setState({alertFail: true})
      } else {
        this.setState({slipUploading: true});
        const orderId = this.props.location.search.split('=')[1] || this.props.location.params.orderId;
        const res = await submitSlip({orderId, slip: this.state.slip});
        console.log('res', res)
        if (res.status === 200) {
          this.setState({alertSlipSuccess: true, slipUploading: false})
        } else {
          this.setState({alertFail: true, slipUploading: false})
        }
      }
    }
  };

  _renderSlip = () => {
    if (this.state.slip && !this.state.payWithCreditCard) {
      return (
        <Container style={{width: window.innerWidth*0.4}}>
          <Image src={this.state.slip} fluid />
        </Container>
      )
    } else {
      return <Container/>
    }
  };

  render() {
    return (
      <Container style={styles.container}>
        <NavBar props={this.props} title={'ชำระเงิน'}/>
        <Row style={{padding: '8px', paddingTop: '10px'}}>
          {this._renderReservationSummary()}
          {this._renderCarSummary()}
          {this._renderFareSummary()}
        </Row>
        <hr/>
        <Row style={{paddingLeft: '8px', paddingRight: '8px'}}>
          {this._renderPayment()}
          {this._renderSlip()}
          {this._imageUploader()}
        </Row>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={50}
          color={'#123abc'}
          loading={this.state.slipUploading}
        />
        {this.state.alertFail ? AlertBox({alertFailCallback: () => this.alertFailCallback()}) : <Container/>}
        {this.state.alertSuccess ? AlertSuccessBox({alertSuccessCallback: () => this.alertSuccessCallback()}) : <Container/>}
        {this.state.alertSlipSuccess ? AlertSlipBox({alertSuccessCallback: () => this.alertSuccessCallback()}) : <Container/>}
        <Button
          style={{marginTop: '15px'}}
          onClick={() => this._payment()}
          variant={'success'}
          block>
          ยืนยัน
        </Button>
      </Container>
    )
  }
}

const styles = {
  summaryText: {
    fontSize: '12px',
  },
  summaryTimeText: {
    fontSize: 12,
    display: 'inline',
    marginLeft: '15px',
  },
  summaryCarText: {
    fontSize: 12,
    display: 'inline',
    marginLeft: '10px',
  },
  priceText: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  reservationSummaryContainer: {
    backgroundColor: 'white',
    marginLeft: '5%',
    width: '90%',
    border: `1px solid ${lightGrey}`,
    borderRadius: 5,
  },
  carSummaryContainer: {
    backgroundColor: 'white',
    marginLeft: '5%',
    width: '90%',
    border: `1px solid ${lightGrey}`,
    borderRadius: 5,
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  inputRowUpper: {
    paddingLeft: '5px',
    paddingTop: '12px',
    alignItems: 'center',
  },
  inputRowLower: {
    paddingLeft: '5px',
    alignItems: 'center',
  },
}

export default Payment
