const key = 'AIzaSyDvNZBFuk-9KBp37uXJH7MrgSX0e3XFmmU'

export const googlePlace = async ({place}) => {
  if (place.length === 0) return null
  try {
    let result = []
    result = await new Promise((resolve, reject) => {
      const service = new window.google.maps.places.AutocompleteService()
      service.getPlacePredictions({input: place, componentRestrictions: {country: 'th'}}, (res) => resolve(res))
    })
    return result
  }
  catch (e) {
    console.log(e)
    return null
  }
}
