import React from 'react'
import {Container, Button} from 'react-bootstrap'
import {test} from "../api/auth"

const liff = window.liff;

class Test extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      accessToken: ''
    }
  }

  componentDidMount = async () => {
    this.initialize()
  }

  initialize = () => {
    liff.init(async (data) => {
      const accessToken = await liff.getAccessToken()
      this.setState({accessToken})
    });
  }

  render() {
    return (
      <Container style={styles.container}>
        <Button onClick={() => test(this.state.accessToken)}>TEST BUTTON</Button>
        <p>{this.state.accessToken}</p>
      </Container>
    )
  }
}

const styles = {
  container: {
    padding: '15px',
  },
}

export default Test
