import axios from 'axios'
import {getHeaders, URL} from './constants'
import {getAccessToken} from "./auth"

export const getVehicle = async () => {
  const headers = await getHeaders()
  return axios.get(`${URL}/line/getvehicle`, {headers})
    .then(res => res)
    .catch(err => console.log('this is from catch', err))
}

export const applyPromo = async (code) => {
  const headers = await getHeaders()
  const data = {
    code,
  }
  return axios.post(`${URL}/line/validatepromotioncode`, data, {headers})
}

export const checkPrice = async ({type, senior_limo, airport_transfer, personal_driver}) => {
  const headers = await getHeaders()
  const data = {
    type,
    senior_limo,
    airport_transfer,
    personal_driver,
  }
  return axios.post(`${URL}/line/checkprice`, data, {headers})
}

export const getOrder = async ({orderId}) => {
  const headers = await getHeaders()
  return axios.get(`${URL}/line/getorder`, {
    headers,
    params: {
      order: orderId
    },
  })
}

export const getAirportTransferOrder = async ({orderId}) => {
  const headers = await getHeaders()
  return axios.get(`${URL}/line/getairporttransferorders`, {
    headers,
    params: {
      orderId: orderId
    },
  })
}

export const createOrder = async ({name, tel, note, senior_limo}) => {
  const headers = await getHeaders()
  const data = {
    name,
    tel,
    note,
    senior_limo,
  }
  return axios.post(`${URL}/line/createseniorlimoorder`, data, {headers})
}

export const createAirportTransferOrder = async ({name, tel, airport_transfer}) => {
  const headers = await getHeaders()
  const data = {
    name,
    tel,
    airport_transfer,
  };
  console.log(data)
  return axios.post(`${URL}/line/createairporttransferorder`, data, {headers})
}

export const createDriverOnDemandOrder = async ({name, tel, note, personal_driver}) => {
  const headers = await getHeaders()
  const data = {
    name,
    tel,
    note,
    personal_driver,
  }
  console.log('data', data)
  return axios.post(`${URL}/line/createpersonaldriverorder`, data, {headers})
}
