import React from 'react'
import {GoogleMap, Marker, withGoogleMap} from 'react-google-maps'
import {Col, Container, Row, Button} from "react-bootstrap"
import {withRouter} from "react-router-dom"
import {back} from "../index"
import {setPlace} from "./helper"
import {customLocationName} from "./constants"

const liff = window.liff

const defaultMapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
}

class MapView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPosition: {},
      center: {},
    }
  }

  static defaultProps = {
    height: window.innerHeight
  }

  componentDidMount = async () => {
    this._getLocation()
  }

  MapComponent = withGoogleMap(props =>
    <GoogleMap
      ref={(map) => this._map = map}
      options={defaultMapOptions}
      defaultZoom={16}
      center={this.state.center}
      onCenterChanged={() => this._updateCenter()}
    >
      {props.children}
    </GoogleMap>
  )

  render() {
    return (
      <div>
        <this.MapComponent
          loadingElement={<div style={{height: `100%`}}/>}
          containerElement={<div style={{height: `${this.props.height}px`}}/>}
          mapElement={<div style={{height: `100%`}}/>}
        >
          <Marker
            position={this.state.currentPosition}
            icon={{
              url: require('../assets/location-pin/current-loc.png'),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
          <Marker
            position={this.state.center}
          />
          {this._confirmationButton()}
        </this.MapComponent>
      </div>
    )
  }

  _confirmationButton = () => {
    return (
      <Container style={{position: 'absolute', bottom: '20px', left: '55%'}}>
        <Button
          onClick={() => this._setPlace()}
        >
          ยืนยันตำแหน่ง
        </Button>
      </Container>
    )
  }

  _setPlace = () => {
    setPlace({
      type: this.props.location.params.type,
      place: {
        name: customLocationName,
        geometry: {
          location: {
            lat: this.state.center.lat(),
            lng: this.state.center.lng(),
          }
        }
      }
    })
    back(this.props, -2)
  }

  _getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentPosition: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
      })
    } else {
      alert("Geolocation is not supported by your browser")
    }
  }

  _updateCenter = () => {
    // from moving the map
    const mapCenter = this._map.getCenter()
    this.setState({
      center: mapCenter,
    })
  }
}

MapView = withRouter(MapView)
export default MapView
