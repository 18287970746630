import axios from 'axios'
import {URL} from './constants'
const liff = window.liff

export const getAccessToken = async () => {
  const accessToken = await new Promise((resolve) => {
    liff.init(async () => {
      const accessToken = await liff.getAccessToken()
      resolve(accessToken)
    })
  })
  return accessToken
}

export const test = async (accessToken) => {
  return axios.get('https://f3eefc5e.ngrok.io/test', {
    params: {
      accessToken
    }
  })
}

export const signup = async () => {
  const accessToken = await getAccessToken()
  const data = {
    'accessToken': accessToken
  }
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios.post(`${URL}/line/adduser`, data, {headers})
}

export const registerDriver = async ({name, tel}) => {
  const accessToken = await getAccessToken()
  const data = {
    'accessToken': accessToken,
    name,
    tel,
  }
  const headers = {
    'Authorization': `Bearer ${accessToken}`
  }
  return axios.post(`${URL}/driver/adddriver`, data, {headers})
}
