import {Button, Col, Container, Row} from "react-bootstrap"
import {styles} from "../styles/map"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {next} from "../index"
import {appBlue, appGold, appRed, darkGrey} from "../styles/color"
import React from "react"
import {setPlace} from "../view/helper"
import {Marker} from "react-google-maps"

// export const CenterMarker = (pickup, center) => {
//   if (pickup === null || pickup.name === 'Custom Position')
//     return (
//       <Marker
//         icon={{
//           url: require('../assets/location-pin/pickup.png'),
//           scaledSize: new window.google.maps.Size(32, 50),
//         }}
//         position={center}/>
//     )
// }

export const DirectionInputComponent = ({type, props, state, currentPosition, pickup, dest, cancel}) => {
  return (
    <Container style={{position: 'absolute', bottom: '20px'}}>
      <Container style={styles.directionInputContainer}>
        <Row style={styles.inputRowUpper}>
          <Col xs={1}><FontAwesomeIcon icon="dot-circle" color={appBlue}/></Col>
          <Col xs={10}>
            <Button
              onClick={() => next({
                props: props,
                pathname: '/booking/place',
                params: {
                  currentPosition: currentPosition,
                  setPlace : ({type, place}) => setPlace({type, place}),
                  type: 'pickup',
                },
              })
              } style={pickup ? styles.text : styles.placeholder}>{_renderTextBox(pickup, 'ตำแหน่งที่จะให้รับ')}</Button>
          </Col>
          {/*<Col xs={3}>*/}
            {/*<Row style={{alignItems: 'flex-end', justifyContent: 'flex-end'}}>*/}
              {/*{_renderDistance()}*/}
              {/*{_renderCancel(cancel, pickup)}*/}
            {/*</Row>*/}
          {/*</Col>*/}
        </Row>
        <Row style={{alignItems: 'center'}}>
          <Col xs={1}><FontAwesomeIcon icon="ellipsis-v" color={darkGrey} style={{marginLeft: '10px'}}/></Col>
          <Col xs={10}>
            <hr/>
          </Col>
          {/*<Col xs={1}><FontAwesomeIcon onClick={() => this._swapPlace()} icon="exchange-alt" color={black} rotation={90}/></Col>*/}
        </Row>
        <Row style={styles.inputRowLower}>
          <Col xs={1}><FontAwesomeIcon icon="map-marker-alt" color={appRed}/></Col>
          <Col xs={10}>
            <Button
              onClick={() => _handleDestClick(props, type, currentPosition)} style={dest ? styles.text : styles.placeholder}>{_renderTextBox(dest, 'ปลายทางที่จะไป')}</Button>
          </Col>
        </Row>
      </Container>
      {SubmitComponent(props, type, state, pickup, dest)}
    </Container>
  )
}

const _handleDestClick = (props, type, currentPosition) => {
  switch (type) {
    case 'airport':
      console.log(type)
      break
    case 'seniorlimo':
      next({
        props: props,
        pathname: '/booking/place',
        params: {
          currentPosition: currentPosition,
          setPlace : ({type, place}) => setPlace({type, place}),
          type: 'dest',
        },
      })
      break
    case 'driver_on_demand':
      next({
        props: props,
        pathname: '/booking/place',
        params: {
          currentPosition: currentPosition,
          setPlace : ({type, place}) => setPlace({type, place}),
          type: 'dest',
        },
      })
      break
  }
}

const SubmitComponent = (props, type, state, pickup, dest) => {
  if (pickup !== null && dest !== null) {
    return (
      <Container style={styles.submitContainer}>
        <Row>
          <Col xs={10}>
            {/*<FontAwesomeIcon icon="car" color={'white'} size='lg' />*/}
            {/*<p style={styles.submitBoxText}>Camry</p>*/}
            {/*<FontAwesomeIcon icon="user" color={'white'} style={{marginLeft: '10px'}}/>*/}
            {/*<p style={styles.submitBoxText}>5</p>*/}
            {/*<FontAwesomeIcon icon="briefcase" color={'white'} style={{marginLeft: '10px'}}/>*/}
            <p style={styles.submitBoxText}>รายละเอียดการจอง</p>
          </Col>
          <Col xs={1}>
            <FontAwesomeIcon onClick={() => _handleSubmit(props, type, state)} icon="arrow-circle-right" color={appGold} size='lg' style={{marginLeft: '10px'}}/>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container />
    )
  }
}

const _handleSubmit = (props, type, state) => {
  switch (type) {
    case 'airport':
      next({
        props: props,
        pathname: '/airport/detail',
        params: state,
      })
      break
    case 'seniorlimo':
      next({
        props: props,
        pathname: '/booking/detail',
        params: state,
      })
      break
    case 'driver_on_demand':
      next({
        props: props,
        pathname: '/dod/detail',
        params: state,
      })
      break
  }
}

const _renderTextBox = (state, text) => {
  return state ? state.name : text
}

const _renderCancel = (cancel, pickup) => {
  if (pickup !== null && pickup.name !== '') {
    return (
      <Button
        onClick={() => cancel()}
        style={styles.placeholder}>X</Button>
    )
  }
}

// const _renderDistance = () => {
//   if (this.state.distance > 0) {
//     return <p style={styles.distanceText}>{this.state.distance} Km</p>
//   }
//   return <p/>
// }
