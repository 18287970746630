import React, {Component} from 'react'
import MapView from './view/booking/1_mapView'
import {signup} from "./api/auth"
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faDotCircle,
  faMapMarkerAlt,
  faEllipsisV,
  faExchangeAlt,
  faChevronCircleLeft,
  faCar,
  faUser,
  faBriefcase,
  faArrowCircleRight,
  faChevronLeft,
  faClock,
  faMapMarkedAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import './App.css'

// line liff
const liff = window.liff

// font awesome
library.add(faDotCircle, faMapMarkerAlt, faEllipsisV, faExchangeAlt, faChevronCircleLeft, faCar, faUser, faBriefcase, faArrowCircleRight, faChevronLeft, faClock, faMapMarkedAlt, faPhone)


class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = async () => {
    this.initialize()
  }

  initialize = () => {
    liff.init(async (data) => {
      const accessToken = await liff.getAccessToken()
      signup(accessToken).then(console.log)
    })
  }
}

export default App
