import React from 'react'
import {Container, Row, Col, Form, Button, DropdownButton, Dropdown, Alert} from 'react-bootstrap'
import {lightGrey} from "../../styles/color"
import NavBar from "../../component/navBar"
import '../../styles/rc-time-picker/index.css'
import 'react-day-picker/lib/style.css';
import {registerDriver} from "../../api/auth";

const liff = window.liff

class RegisterDriver extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      tel: '',
      alertShow: false,
      alertSuccess: false,
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  _renderCustomerDetail = () => (
    <Form.Group>
      <Form.Label>รายละเอียดผู้สมัคร</Form.Label>
      <Form.Control value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} type="text" placeholder="ซื่อ" size="sm" />
      <Form.Control value={this.state.tel} onChange={(event) => this.setState({tel: event.target.value})} style={{marginTop: '8px'}} type="number" placeholder="เบอร์" size="sm" />
    </Form.Group>
  )

  _buttonDisable = () => {
    return !(this.state.name !== '' && this.state.tel !== '')
  }

  _signup = async () => {
    const res = await registerDriver({
      name: this.state.name,
      tel: this.state.tel,
    })
    if (res.status === 200) {
      this.setState({alertSuccess: true})
    } else {
      this.setState({alertShow: true})
    }
  }

  AlertSuccessBox = () => (
    <Alert show={this.state.alertSuccess} variant='success'>
      <Alert.Heading>Done!</Alert.Heading>
      <p>
        เรากำลังพิจารณาใบสมัครของท่าน กรุณารอการติดต่อกลับ
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={() => {
          this.setState({alertSuccess: false})
          liff.closeWindow()
        }} variant="outline-success">
          Close
        </Button>
      </div>
    </Alert>
  )

  AlertBox = () => (
    <Alert show={this.state.alertShow} variant='danger'>
      <Alert.Heading>Error!</Alert.Heading>
      <p>
        ใบสามารถสมัครได้ กรุณาลองใหม่อีกครั้ง
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={() => this.setState({alertShow: false})} variant="outline-danger">
          Close
        </Button>
      </div>
    </Alert>
  )

  render() {
    return (
      <Container style={styles.container}>
        <NavBar props={this.props} title={'สมัครเป็นคนขับ'}/>
        <Row style={{padding: '8px'}}>
          {this.AlertSuccessBox()}
          {this.AlertBox()}
          <Col xs={12} style={{border: `1px solid ${lightGrey}`, borderRadius: '5px', width: this.props.width, paddingTop: '10px', paddingBottom: '10px'}}>
            <Form>
              {this._renderCustomerDetail()}
            </Form>
            <Button disabled={this._buttonDisable()} onClick={() => this._signup()} variant={'success'} block>ส่งใบสมัคร</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const styles = {
}

export default RegisterDriver
