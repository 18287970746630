import React from 'react'
import {Container, Row, Col, Form, Button, DropdownButton, Dropdown, Alert} from 'react-bootstrap'
import {darkGrey, black, lightGrey, appRed, appBlue, appGold, green} from "../../styles/color"
import NavBar from "../../component/navBar"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import moment from 'moment'
import TimePicker from 'rc-time-picker';
import '../../styles/rc-time-picker/index.css'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {next} from "../../index"
import {applyPromo, checkPrice, createAirportTransferOrder, getVehicle} from "../../api/reservation"
import {numberWithCommas, toDateString, formatDate, applyDiscount} from "../helper"

class ReservationDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      carModel: [],
      name: '',
      tel: '',
      type: '',
      departureFlight: '',
      departureDate: '',
      departureTime: '',
      departureNote: '',
      departureCarModel: {
        name: 'เลือกพาหนะ',
      },
      arrivalFlight: '',
      arrivalDate: '',
      arrivalTime: '',
      arrivalNote: '',
      arrivalCarModel: {
        name: 'เลือกพาหนะ',
      },
      promoCode: '',
      promo: {},
      price: 0,
      alertShow: false,
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  componentDidMount = async () => {
    console.log(this.props)
    const carModel = await getVehicle()
    this.setState({carModel: carModel.data})
  }

  applyPromo = async () => {
    const promoDetail = await applyPromo(this.state.promoCode)
    const {status, data} = promoDetail
    if (status === 200 && data.valid) {
      this.setState({promo: data})
    } else {
      this.setState({promo: {valid: false}})
    }
  }

  getPrice = async () => {
    let price
    switch (this.state.type) {
      case 'เที่ยวเดียว':
        price = this.state.departureCarModel.name !== 'เลือกพาหนะ' ?
          await checkPrice({
            type: 'airport_transfer',
            airport_transfer: {
              to_airport: {
                vehicle: this.state.departureCarModel._id,
              }
            }
          })
          : 0
        break
      case 'ไป-กลับ':
        price = [this.state.departureCarModel.name, this.state.arrivalCarModel.name].every(s => s !== 'เลือกพาหนะ') ?
          await checkPrice({
            type: 'airport_transfer',
            airport_transfer: {
              to_airport: {
                vehicle: this.state.departureCarModel._id,
              },
              from_airport: {
                vehicle:  this.state.arrivalCarModel._id,
              },
            }
          })
          : 0
        break
    }
    if (price !== 0) {
      this.setState({price: price.data});
    }
  }

  handleDayChange = async (type, date) => {
    switch (type) {
      case 'departure':
        await this.setState({departureDate: date.toLocaleDateString()})
        break
      case 'arrival':
        await this.setState({arrivalDate: date.toLocaleDateString()})
        break
    }
    this.getPrice()
  }

  handleTimeChange = (type, time) => {
    switch (type) {
      case 'departure':
        if (time === null) this.setState({departureTime: ''})
        else this.setState({departureTime: time.format('HH:mm')})
        break
      case 'arrival':
        if (time === null) this.setState({departureTime: ''})
        else this.setState({arrivalTime: time.format('HH:mm')})
        break
    }
  }

  handleCarModelChange = async (type, car) => {
    switch (type) {
      case 'departure':
        await this.setState({departureCarModel: car})
        break
      case 'arrival':
        await this.setState({arrivalCarModel: car})
        break
    }
    this.getPrice()
  }

  _renderCustomerDetail = () => (
    <Form.Group>
      <Form.Label>รายละเอียดผู้ติดต่อ</Form.Label>
      <Form.Control value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} type="text" placeholder="ซื่อ" size="sm" />
      <Form.Control value={this.state.tel} maxLength={10}
                    onChange={(event) => {if (!isNaN(event.target.value)) {this.setState({tel: event.target.value})}}}
                    style={{marginTop: '8px'}} placeholder="เบอร์" size="sm" />
      <Form.Label style={{marginTop: '10px'}}>รูปแบบการจอง</Form.Label>
      <Form.Control value={this.state.type} onChange={(event) => this.setState({type: event.target.value})} as="select" size='sm'>
        <option value="" disabled selected>เลือก</option>
        <option>เที่ยวเดียว</option>
        <option>ไป-กลับ</option>
      </Form.Control>
    </Form.Group>
  )

  _renderCarModel = (type) => (
    <Form.Group controlId="formCarModel" style={{marginTop: '8px'}}>
      <Form.Label>พาหนะพรีเมี่ยมสำหรับคุณ</Form.Label>
      <DropdownButton id="dropdown-basic-button" title={type === 'departure' ? this.state.departureCarModel.name : this.state.arrivalCarModel.name} variant='primary' size='sm'>
        {this.state.carModel.map(item => {
          return (
            <Dropdown.Item style={{width: this.props.width*0.85}} onClick={() => type === 'departure' ? this.handleCarModelChange('departure', item) : this.handleCarModelChange('arrival', item)}>
              <Row>
                <Col xs={7}>{item.name}</Col>
                <Col xs={5}>
                  <FontAwesomeIcon style={{marginLeft: '10px'}} icon="user"/> 5 <FontAwesomeIcon style={{marginLeft: '5px'}} icon="briefcase"/> 5
                </Col>
              </Row>
            </Dropdown.Item>
          )
        })}
      </DropdownButton>
    </Form.Group>
  )

  _renderDeparture = () => {
    return (
      <Form.Group>
        <Form.Label>ข้อมูลขาไป</Form.Label>
        <Form.Control style={{marginBottom: '8px'}} value={this.state.departureFlight} onChange={(event) => this.setState({departureFlight: event.target.value})} type="text" placeholder="Flight No." size="sm" />
        <Form.Row>
          <Col>
            <DayPickerInput
              formatDate={(date) => formatDate(date)}
              format="DD/MM/YYYY"
              placeholder='วันที่จอง'
              style={{border: `1px solid ${lightGrey}`, borderRadius: '4px', paddingLeft: '5px', height: '30px', fontSize: '14px'}}
              onDayChange={(day) => this.handleDayChange('departure', day)} />
          </Col>
          <Col>
            <TimePicker
              placeholder='เวลาที่จอง'
              style={{ paddingLeft: '5px' }}
              showSecond={false}
              onChange={(time) => this.handleTimeChange('departure', time)}
              minuteStep={15}
            />
          </Col>
        </Form.Row>
        <Form.Label style={{marginTop: '5px'}}>Note</Form.Label>
        <Form.Control value={this.state.departureNote} onChange={(event) => this.setState({departureNote: event.target.value})} as="textarea" rows="3" size="sm" />
        {this._renderCarModel('departure')}
      </Form.Group>
    )
  }

  _renderArrival = () => {
    if (this.state.type === 'ไป-กลับ') {
      return (
        <Form.Group>
          <hr/>
          <Form.Label>ข้อมูลขากลับ</Form.Label>
          <Form.Control style={{marginBottom: '8px'}} value={this.state.arrivalFlight} onChange={(event) => this.setState({arrivalFlight: event.target.value})} type="text" placeholder="Flight No." size="sm" />
          <Form.Row>
            <Col>
              <DayPickerInput
                formatDate={(date) => formatDate(date)}
                format="DD/MM/YYYY"
                placeholder='วันที่จอง'
                style={{border: `1px solid ${lightGrey}`, borderRadius: '4px', paddingLeft: '5px', height: '30px', fontSize: '14px'}}
                onDayChange={(day) => this.handleDayChange('arrival', day)} />
            </Col>
            <Col>
              <TimePicker
                placeholder='เวลาที่จอง'
                style={{ paddingLeft: '5px' }}
                showSecond={false}
                onChange={(time) => this.handleTimeChange('arrival', time)}
                minuteStep={15}
              />
            </Col>
          </Form.Row>
          <Form.Label style={{marginTop: '5px'}}>Note</Form.Label>
          <Form.Control value={this.state.arrivalNote} onChange={(event) => this.setState({arrivalNote: event.target.value})} as="textarea" rows="3" size="sm" />
          {this._renderCarModel('arrival')}
        </Form.Group>
      )
    }
  }

  _renderFareSummary = () => (
    <Form.Group controlId="formFareSummary">
      <Form.Row style={{marginTop: '8px'}}>
        <Col xs={9}>
          <Form.Control value={this.state.promoCode} onChange={(event) => this.setState({promoCode: event.target.value})} type="text" placeholder="Promo Code" size="sm" />
        </Col>
        <Col xs={3}>
          <Button onClick={() => this.applyPromo()} variant="info" size='sm' block>ยืนยัน</Button>
        </Col>
      </Form.Row>
      {this._renderPromoStatus()}
      <Form.Row style={{marginTop: '20px'}}>
        <Col xs={7}>
          <Form.Label>ค่าบริการทั้งหมด</Form.Label>
        </Col>
        <Col xs={3}>
          <p style={styles.priceText}>{numberWithCommas(applyDiscount(this.state.price, this.state.promo))}</p>
        </Col>
        <Col xs={2}>
          <p style={{textAlign: 'right'}}>บาท</p>
        </Col>
      </Form.Row>
    </Form.Group>
  )

  _buttonDisable = () => {
    let common = [this.state.name, this.state.tel, this.state.type].every(s => s !== '') && this.state.price > 0
    let departure = [this.state.departureFlight, this.state.departureDate, this.state.departureTime].every(s => s !== '') && this.state.departureCarModel.name !== 'เลือกพาหนะ'
    let arrival = [this.state.arrivalFlight, this.state.arrivalDate, this.state.arrivalTime].every(s => s !== '') && this.state.arrivalCarModel.name !== 'เลือกพาหนะ'
    switch (this.state.type) {
      case 'เที่ยวเดียว':
        return common && departure
      case 'ไป-กลับ':
        return common && departure && arrival
    }
  }

  AlertBox = () => (
    <Alert show={this.state.alertShow} variant='danger'>
      <Alert.Heading>Error creating your order!</Alert.Heading>
      <p>
        Your booking cannot be made. Please try again :(
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={() => this.setState({alertShow: false})} variant="outline-danger">
          Close
        </Button>
      </div>
    </Alert>
  )

  _renderPromoStatus = () => {
    switch (this.state.promo.valid) {
      case (true):
        return <p style={{margin: 5, color: green}}>Discount: {this.state.promo.amount} {this.state.promo.type}</p>
      case (false):
        return <p style={{margin: 5, color: appRed}}>Promo code is invalid</p>
    }
  };

  render() {
    return (
      <Container style={styles.container}>
        <NavBar props={this.props} title={'ข้อมูลการจอง'}/>
        <Row style={{padding: '8px'}}>
          {this.AlertBox()}
          <Col xs={12} style={{border: `1px solid ${lightGrey}`, borderRadius: '5px', width: this.props.width, paddingTop: '10px', paddingBottom: '10px'}}>
            <Form>
              {this._renderCustomerDetail()}
              <hr/>
              {this._renderDeparture()}
              {this._renderArrival()}
              <hr/>
              {this._renderFareSummary()}
            </Form>
            <Button disabled={!this._buttonDisable()} onClick={() => this._createOrder()} variant={'success'} block>จองพาหนะ</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  _createOrder = async () => {
    const {params} = this.props.location
    const name = this.state.name;
    const tel = this.state.tel;
    const airport_transfer = this.state.type === 'เที่ยวเดียว' ?
      {
        to_airport: {
          note: this.state.departureNote,
          vehicle: this.state.departureCarModel._id,
          flight_no: this.state.departureFlight,
          pickup_time: toDateString(this.state.departureDate, this.state.departureTime),
        },
        pickup_location: {
          name: params.pickup.name,
          latitude: params.pickup.geometry.location.lat,
          longitude: params.pickup.geometry.location.lng,
        },
        destination_location: {
          name: params.dest.name,
          latitude: params.dest.geometry.location.lat,
          longitude: params.dest.geometry.location.lng,
        }
      } :
      {
        to_airport: {
          note: this.state.departureNote,
          vehicle: this.state.departureCarModel._id,
          flight_no: this.state.departureFlight,
          pickup_time: toDateString(this.state.departureDate, this.state.departureTime),
        },
        from_airport: {
          note: this.state.arrivalNote,
          vehicle: this.state.arrivalCarModel._id,
          flight_no: this.state.arrivalFlight,
          pickup_time: toDateString(this.state.arrivalDate, this.state.arrivalTime),
        },
        pickup_location: {
          name: params.pickup.name,
          latitude: params.pickup.geometry.location.lat,
          longitude: params.pickup.geometry.location.lng,
        },
        destination_location: {
          name: params.dest.name,
          latitude: params.dest.geometry.location.lat,
          longitude: params.dest.geometry.location.lng,
        }
      };

    const res = await createAirportTransferOrder({name, tel, airport_transfer})
    console.log('create airport transfer order res', {res})
    if (res.status === 200) {
      const orderId = this.state.type === 'เที่ยวเดียว' ? res.data._id : res.data[0]._id;
      next({
        props: this.props,
        pathname: '/airport/payment',
        params: {orderId},
      })
    } else {
      this.setState({alertShow: true})
    }
  }
}

const styles = {
  priceText: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
}

export default ReservationDetail
