import React from 'react'
import {Container, Row, Col, Image, Button} from 'react-bootstrap'
import { Carousel } from 'react-responsive-carousel'
import '../../lib/react-responsive-carousel/lib/styles/carousel.min.css'

const md = 768

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.about=null
    this.contact=null
    this.state = {}
  }

  static defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  _scroller = (el) => {
    window.scrollTo({
      top: el,
      behavior: 'smooth',
    })
  }

  _firstRow = () => {
    if (this.props.width >= md) {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/first-banner.png')}`, backgroundSize: '100% 100%', height: this.props.width/1.802816901}}>
          <Col xs={1}/>
          <Col xs={2} style={{marginTop: '20px'}}>
            <Image src={require('../../assets/logo.png')} width={this.props.width*0.11} />
          </Col>
          <Col/>
          <Col xs={4} style={{marginTop: '45px'}}>
            <Row style={styles.topRightButton}>
              <a onClick={() => this._scroller(this.about.offsetTop)}>
                <p style={styles.topRightButton}>About Us</p>
              </a>
              <a onClick={() => this._scroller(this.contact.offsetTop)}>
                <p style={styles.topRightButton}>Contact Us</p>
              </a>
              <Image style={{marginRight: '10px', marginTop: '5px'}} src={require('../../assets/fb-icon.png')} width={15} height={15} />
              <Image style={{marginTop: '5px'}} src={require('../../assets/ig-icon.png')} width={15} height={15} />
            </Row>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/first-banner-mobile.png')}`, backgroundSize: '100% 100%', height: this.props.width/0.833333333, paddingTop: '10px'}}>
          <Col xs={5}>
            <Row>
              <a onClick={() => this._scroller(this.about.offsetTop)}>
                <p style={styles.topRightButtonMobile}>About Us</p>
              </a>
              <a onClick={() => this._scroller(this.contact.offsetTop)}>
                <p style={styles.topRightButtonMobile}>Contact Us</p>
              </a>
            </Row>
          </Col>
          <Col xs={1}>
            <Image src={require('../../assets/logo.png')} width={this.props.width*0.15} />
          </Col>
          <Col/>
          <Col xs={2}>
            <Image style={{marginRight: '5px'}} src={require('../../assets/fb-icon.png')} width={12} height={12} />
            <Image src={require('../../assets/ig-icon.png')} width={12} height={12} />
          </Col>
        </Row>
      )
    }
  }


  _secondRow = () => {
    if (this.props.width >= md) {
      return (
        <div ref={ (ref) => this.about=ref}>
          <Row style={{height: this.props.height * 0.5, alignContent: 'center', justifyContent: 'center'}}>
            <div>
              <p style={{fontSize: '38px', fontWeight: 700, lineHeight: '120%'}}>
                About Us
              </p>
            </div>
            <div style={{width: '100%'}}/>
            <div>
              <p style={{
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: 400,
                lineHeight: '120%',
                paddingLeft: '20%',
                paddingRight: '20%'
              }}>
                แพลตฟอร์มที่ตอบโจทย์ ทุกไลฟ์สไตล์ในช่วงชีวิตของคุณให้ทุกการใช้ชีวิตขอคุณเป็นเรื่องง่าย
                พนักงานของเราทุกคนผ่านการตรวจสอบประวัติอย่างระเอียด และอบรมจากบริษัทชั้นนำ พร้อมดูแลคุณดีที่สุด
              </p>
            </div>
          </Row>
        </div>
      )
    } else {
      return (
        <div ref={ (ref) => this.about=ref}>
          <Row style={{height: this.props.height * 0.45, alignContent: 'center', justifyContent: 'center'}}>
            <div>
              <p style={{fontSize: '38px', fontWeight: 700, lineHeight: '120%'}}>
                About Us
              </p>
            </div>
            <div style={{width: '100%'}}/>
            <div>
              <p style={{
                textAlign: 'center',
                fontSize: '15px',
                fontWeight: 400,
                lineHeight: '120%',
                paddingLeft: '10%',
                paddingRight: '10%'
              }}>
                แพลตฟอร์มที่ตอบโจทย์ ทุกไลฟ์สไตล์ในช่วงชีวิตของคุณให้ทุกการใช้ชีวิตขอคุณเป็นเรื่องง่าย
                พนักงานของเราทุกคนผ่านการตรวจสอบประวัติอย่างระเอียด และอบรมจากบริษัทชั้นนำ พร้อมดูแลคุณดีที่สุด
              </p>
            </div>
          </Row>
        </div>
      )
    }
  }

  _thirdRow = () => {
    if (this.props.width >= md) {
      return (
        <div ref={ (ref) => this.contact=ref}>
          <Row style={{
            backgroundImage: `url(${require('../../assets/second-banner.png')}`,
            backgroundSize: '100% 100%',
            height: this.props.width / 2.342177493
          }}/>
        </div>
      )
    } else {
      return (
        <div ref={ (ref) => this.contact=ref}>
          <Row style={{
            backgroundImage: `url(${require('../../assets/second-banner-mobile.png')}`,
            backgroundSize: '100% 100%',
            height: this.props.width / 1.209677419
          }}/>
        </div>
      )
    }
  }

  _fourthRow = () => {
    if (this.props.width >= md) {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/third-banner.png')}`, backgroundSize: '100% 100%', height: this.props.width/4.122383253}}/>
      )
    } else {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/third-banner-mobile.png')}`, backgroundSize: '100% 100%', height: this.props.width/1.893939394}}/>
      )
    }
  }


  _carouselRow = () => {
    if (this.props.width >= md) {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/carousel-bg.png')}`, backgroundSize: '100% 100%', height: this.props.height*0.6, justifyContent: 'center', alignItems: 'center'}}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            centerMode
            centerSlidePercentage={50}
          >
            <Col>
              <Image src={require('../../assets/camry.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/h1.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/vellfire.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/alphard.png')} />
            </Col>
          </Carousel>

        </Row>
      )
    } else {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/carousel-bg.png')}`, backgroundSize: '100% 100%', height: this.props.height*0.3, justifyContent: 'center', alignItems: 'center'}}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            centerMode
            centerSlidePercentage={50}
          >
            <Col>
              <Image src={require('../../assets/camry.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/h1.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/vellfire.png')} />
            </Col>
            <Col>
              <Image src={require('../../assets/alphard.png')} />
            </Col>
          </Carousel>

        </Row>
      )
    }
  }

  _fifthRow = () => {
    if (this.props.width >= md) {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/fourth-banner.png')}`, backgroundSize: '100% 100%', height: this.props.width/2.323049002}}/>
      )
    } else {
      return (
        <Row style={{backgroundImage: `url(${require('../../assets/fourth-banner-mobile.png')}`, backgroundSize: '100% 100%', height: this.props.width/1.209677419}}/>
      )
    }
  }

  _footer = () => {
    if (this.props.width >= md) {
      const iconSize = 25
      return (
        <Row style={{height: this.props.height*0.4, backgroundColor: '#262a30', justifyContent: 'center', alignItems: 'center'}}>
          <Row>
            <Col style={{paddingTop: this.props.height*0.06}}>
              <Image src={require('../../assets/logo-white.png')} width={this.props.width*0.11} />
            </Col>
          </Row>
          <div style={{width: this.props.width}}/>
          <Row>
            <a onClick={() => this._scroller(this.about.offsetTop)}>
              <p style={{fontSize: '15px', fontWeight: '800', color: 'white', cursor: 'pointer'}}>About Us</p>
            </a>
            <a onClick={() => this._scroller(this.contact.offsetTop)}>
              <p style={{fontSize: '15px', fontWeight: '800', color: 'white', marginLeft: this.props.width*0.04, cursor: 'pointer'}}>Contact Us</p>
            </a>
          </Row>
          <div style={{width: this.props.width}}/>
          <Row style={{paddingBottom: this.props.height*0.06, cursor: 'pointer'}}>
            <Image src={require('../../assets/twitter-icon.png')} width={iconSize} height={iconSize} />
            <Image style={{marginLeft: this.props.width*0.01, marginRight: this.props.width*0.01}} src={require('../../assets/fb-icon.png')} width={iconSize} height={iconSize} />
            <Image src={require('../../assets/ig-icon.png')} width={iconSize} height={iconSize} />
          </Row>
        </Row>
      )
    } else {
      const iconSize = 15
      return (
        <Row style={{height: this.props.height*0.3, backgroundColor: '#262a30', justifyContent: 'center', alignItems: 'center'}}>
          <Row>
            <Col style={{paddingTop: this.props.height*0.06}}>
              <Image src={require('../../assets/logo-white.png')} width={this.props.width*0.2} />
            </Col>
          </Row>
          <div style={{width: this.props.width}}/>
          <Row>
            <a onClick={() => this._scroller(this.about.offsetTop)}>
              <p style={{fontSize: '11px', fontWeight: '800', color: 'white'}}>About Us</p>
            </a>
            <a onClick={() => this._scroller(this.contact.offsetTop)}>
              <p style={{fontSize: '11px', fontWeight: '800', color: 'white', marginLeft: this.props.width*0.04}}>Contact Us</p>
            </a>
          </Row>
          <div style={{width: this.props.width}}/>
          <Row style={{paddingBottom: this.props.height*0.06}}>
            <Image src={require('../../assets/twitter-icon.png')} width={iconSize} height={iconSize} />
            <Image style={{marginLeft: this.props.width*0.01, marginRight: this.props.width*0.01}} src={require('../../assets/fb-icon.png')} width={iconSize} height={iconSize} />
            <Image src={require('../../assets/ig-icon.png')} width={iconSize} height={iconSize} />
          </Row>
        </Row>
      )
    }
  }

  _copyright = () => (
    <Row style={{height: this.props.height*0.04, justifyContent: 'center', alignItems: 'center', backgroundColor: '#0d0e10'}}>
        <p style={{color: 'white'}}>Copyright</p>
    </Row>
  )

  render() {
    console.log('this is new version')
    return (
      <Container fluid>
        {this._firstRow()}
        {this._secondRow()}
        {this._thirdRow()}
        {this._fourthRow()}
        {this._carouselRow()}
        {this._fifthRow()}
        {this._footer()}
        {/*{this._copyright()}*/}
      </Container>
    );
  }
}

const styles = {
  firstRowCenter: {
    marginTop: '6%',
    marginLeft: '10px'
  },
  topRightButton: {
    color:'white',
    fontSize: '15px',
    fontWeight: 800,
    marginRight: '15px',
    cursor: 'pointer',
  },
  topRightButtonMobile: {
    color:'white',
    fontSize: '10px',
    fontWeight: 800,
    marginLeft: '10px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  topRightCol: {
    marginTop: '40px',
  },
}

export default Landing
