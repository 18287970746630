import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css'
import App from './App'
import Landing from './view/landing/landing'
import MapView from './view/booking/1_mapView'
import PlaceSearch from './view/booking/2_placeSearch'
import ReservationDetail from './view/booking/3_reservationDetail'
import Payment from './view/booking/4_payment'
import CreditCardScreen from './view/booking/4a_creditCard'
import AirportMap from './view/airport/1_mapView'
import AirportReservationDetail from './view/airport/2_reservationDetail'
import AirportPayment from './view/airport/3_payment'
import DODMapView from './view/driver_on_demand/1_mapView'
import DODReservationDetail from './view/driver_on_demand/2_reservationDetail'
import DODPayment from './view/driver_on_demand/3_payment'
import SelectLocation from './view/selectLocation'
import Trip from './view/driver/trip'
import RegisterDriver from './view/driver/register'
import DriverTasks from './view/driver/task'
import Test from './view/test'
import UserActiveTrip from './view/userActiveTrip'
import NotFound from './view/notFound'
import * as serviceWorker from './serviceWorker'

const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path={'/'} component={Landing} />
        <Route path={'/landing'} component={Landing} />
        <Route path={'/booking/map'} component={MapView} />
        <Route path={'/booking/place'} component={PlaceSearch} />
        <Route path={'/booking/detail'} component={ReservationDetail} />
        <Route path={'/booking/payment'} component={Payment} />
        <Route path={'/booking/creditcard'} component={CreditCardScreen} />
        <Route path={'/airport/map'} component={AirportMap} />
        <Route path={'/airport/detail'} component={AirportReservationDetail} />
        <Route path={'/airport/payment'} component={AirportPayment} />
        <Route path={'/dod/map'} component={DODMapView} />
        <Route path={'/dod/detail'} component={DODReservationDetail} />
        <Route path={'/dod/payment'} component={DODPayment} />
        <Route path={'/user-trip'} component={UserActiveTrip} />
        <Route path={'/select-location'} component={SelectLocation} />
        <Route path={'/driver/trip'} component={Trip} />
        <Route path={'/driver/register'} component={RegisterDriver} />
        <Route path={'/driver/tasks'} component={DriverTasks} />
        <Route path={'/test'} component={Test} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </Router>
)

export const next = ({props, pathname, params}) => {
  props.history.push({pathname, params})
}

export const back = (props, page) => {
  props.history.go(page || -1)
}

ReactDOM.render(routing, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
