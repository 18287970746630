import {getAccessToken} from "./auth"

export const URL = 'https://api.bestlifethailand.com'

export const getHeaders = async () => {
  const accessToken = await getAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return headers
}
