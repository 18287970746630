import React from 'react'
import {Container, Row, Col, Form, Button, DropdownButton, Dropdown, Alert} from 'react-bootstrap'
import {darkGrey, black, lightGrey, appRed, appBlue, appGold, green} from "../../styles/color"
import NavBar from "../../component/navBar"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import moment from 'moment'
import TimePicker from 'rc-time-picker';
import '../../styles/rc-time-picker/index.css'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {next} from "../../index"
import {applyPromo, checkPrice, createDriverOnDemandOrder, createOrder, getVehicle} from "../../api/reservation"
import {applyDiscount, formatDate, numberWithCommas, toDateString} from "../helper"

class ReservationDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      tel: '',
      duration: '',
      date: '',
      time: '',
      note: '',
      promoCode: '',
      promo: {},
      price: 0,
      alertShow: false,
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  componentDidMount = async () => {
    console.log(this.props)
  }

  applyPromo = async () => {
    const promoDetail = await applyPromo(this.state.promoCode)
    const {status, data} = promoDetail
    if (status === 200 && data.valid) {
      this.setState({promo: data})
    } else {
      this.setState({promo: {valid: false}})
    }
  }

  getPrice = async () => {
    if (this.state.duration !== '') {
      const price = await checkPrice({
        type: 'personal_driver',
        personal_driver: {
          duration: parseInt(this.state.duration),
        },
      })
      this.setState({price: price.data})
    }
  }

  applyPromo = async () => {
    const promoDetail = await applyPromo(this.state.promoCode)
    const {status, data} = promoDetail
    if (status === 200 && data.valid) {
      this.setState({promo: data})
    } else {
      this.setState({promo: {valid: false}})
    }
  }

  handleDayChange = async (date) => {
    await this.setState({date: date.toLocaleDateString()})
  }

  handleTimeChange = async (time) => {
    await this.setState({time: time.format('HH:mm')})
    this.getPrice()
  }

  // handleCarModelChange = async (car) => {
  //   console.log('car', car)
  //   await this.setState({selectedCarModel: car})
  //   this.getPrice()
  // }

  _renderCustomerDetail = () => (
    <Form.Group>
      <Form.Label>รายละเอียดผู้ติดต่อ</Form.Label>
      <Form.Control value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} type="text" placeholder="ซื่อ" size="sm" />
      <Form.Control value={this.state.tel} maxLength={10}
                    onChange={(event) => {if (!isNaN(event.target.value)) {this.setState({tel: event.target.value})}}}
                    style={{marginTop: '8px'}} placeholder="เบอร์" size="sm" />
      <Form.Row style={{marginTop: '8px'}}>
        <Col>
          <DayPickerInput
            formatDate={(date) => formatDate(date)}
            format="DD/MM/YYYY"
            placeholder='วันที่จอง'
            style={{border: `1px solid ${lightGrey}`, borderRadius: '4px', paddingLeft: '5px', height: '30px', fontSize: '14px'}}
            onDayChange={this.handleDayChange} />
        </Col>
        <Col>
          <TimePicker
            placeholder='เวลาที่จอง'
            style={{ paddingLeft: '5px' }}
            showSecond={false}
            onChange={this.handleTimeChange}
            minuteStep={15}
          />
        </Col>
      </Form.Row>
      <Form.Label style={{marginTop: '10px'}}>จำนวนชั่วโมง</Form.Label>
      <Form.Control value={this.state.duration} onChange={async (event) => {
        await this.setState({duration: event.target.value})
        this.getPrice()
      }} as="select" size='sm'>
        <option value="" disabled selected>เลือก</option>
        <option>4</option>
        <option>8</option>
        <option>12</option>
      </Form.Control>
      <Form.Label style={{marginTop: '8px'}}>Note</Form.Label>
      <Form.Control as="textarea" rows="3" size="sm" />
    </Form.Group>
  )

  // _renderCarModel = () => (
  //   <Form.Group controlId="formCarModel">
  //     <Form.Label>พาหนะพรีเมี่ยมสำหรับคุณ</Form.Label>
  //     <DropdownButton id="dropdown-basic-button" title={this.state.selectedCarModel.name} variant='primary' size='sm'>
  //       {this.state.carModel.map(item => {
  //         return (
  //           <Dropdown.Item style={{width: this.props.width*0.85}} onClick={() => this.handleCarModelChange(item)}>
  //             <Row>
  //               <Col xs={7}>{item.name}</Col>
  //               <Col xs={5}>
  //                 <FontAwesomeIcon style={{marginLeft: '10px'}} icon="user"/> 5 <FontAwesomeIcon style={{marginLeft: '5px'}} icon="briefcase"/> 5
  //               </Col>
  //             </Row>
  //           </Dropdown.Item>
  //         )
  //       })}
  //     </DropdownButton>
  //   </Form.Group>
  // )

  _renderPromoStatus = () => {
    switch (this.state.promo.valid) {
      case (true):
        return <p style={{margin: 5, color: green}}>Discount: {this.state.promo.amount} {this.state.promo.type}</p>
      case (false):
        return <p style={{margin: 5, color: appRed}}>Promo code is invalid</p>
    }
  };

  _renderFareSummary = () => (
    <Form.Group controlId="formFareSummary">
      <Form.Row style={{marginTop: '8px'}}>
        <Col xs={9}>
          <Form.Control value={this.state.promoCode} onChange={(event) => this.setState({promoCode: event.target.value})} type="text" placeholder="Promo Code" size="sm" />
        </Col>
        <Col xs={3}>
          <Button onClick={() => this.applyPromo()} variant="info" size='sm' block>ยืนยัน</Button>
        </Col>
      </Form.Row>
      {this._renderPromoStatus()}
      <Form.Row style={{marginTop: '20px'}}>
        <Col xs={7}>
          <Form.Label>ค่าบริการทั้งหมด</Form.Label>
        </Col>
        <Col xs={3}>
          <p style={styles.priceText}>{numberWithCommas(applyDiscount(this.state.price, this.state.promo))}</p>
        </Col>
        <Col xs={2}>
          <p style={{textAlign: 'right'}}>บาท</p>
        </Col>
      </Form.Row>
    </Form.Group>
  )

  AlertBox = () => (
    <Alert show={this.state.alertShow} variant='danger'>
      <Alert.Heading>Error creating your order!</Alert.Heading>
      <p>
        Your booking cannot be made. Please try again :(
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button onClick={() => this.setState({alertShow: false})} variant="outline-danger">
          Close
        </Button>
      </div>
    </Alert>
  )

  _buttonDisable = () => {
    return [this.state.name, this.state.tel, this.state.duration, this.state.date, this.state.time].every(s => s === '') || this.state.price === 0
  }

  render() {
    return (
      <Container style={styles.container}>
        <NavBar props={this.props} title={'ข้อมูลการจอง'}/>
        <Row style={{padding: '8px'}}>
          {this.AlertBox()}
          <Col xs={12} style={{border: `1px solid ${lightGrey}`, borderRadius: '5px', width: this.props.width, paddingTop: '10px', paddingBottom: '10px'}}>
            <Form>
              {this._renderCustomerDetail()}
              <hr/>
              {/*{this._renderCarModel()}*/}
              {/*<hr/>*/}
              {this._renderFareSummary()}
            </Form>
            <Button disabled={this._buttonDisable()} onClick={() => this._createOrder()} variant={'success'} block>จองพาหนะ</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  _createOrder = async () => {
    const {params} = this.props.location
    const name = this.state.name;
    const tel = this.state.tel;
    const note = this.state.note;
    const personal_driver = {
      duration: parseInt(this.state.duration),
      pickup_location: {
        name: params.pickup.name,
        latitude: params.pickup.geometry.location.lat,
        longitude: params.pickup.geometry.location.lng,
      },
      destination_location: {
        name: params.dest.name,
        latitude: params.dest.geometry.location.lat,
        longitude: params.dest.geometry.location.lng,
      },
      pickup_time: toDateString(this.state.date, this.state.time),
    };
    const res = await createDriverOnDemandOrder({name, tel, note, personal_driver});
    if (res.status === 200) {
      const orderId = res.data._id;
      next({
        props: this.props,
        pathname: '/dod/payment',
        params: {orderId},
      })
    } else {
      this.setState({alertShow: true})
    }
  }
}

const styles = {
  priceText: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
}

export default ReservationDetail
