import {Marker} from "react-google-maps"
import React from "react"
import moment from "moment-timezone";
import {getDistance} from 'geolib';

const isEmpty = (obj) => {
  for(var key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const setPlace = ({type, place}) => {
  localStorage.setItem(type, JSON.stringify(place))
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toDateString = (date, time) => {
  return new Date(`${date} ${time}`).toISOString()
}

export const getDateTime = (ISODate) => {
  const date = moment(ISODate).format('DD/MM/YYYY');
  const time = moment(ISODate).tz('Asia/Bangkok').format('HH:mm');
  return {date, time}
}

export const sleep = (ms) => {
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
  }
}

export const _getDistance = (start, dest) => {
  return (getDistance(start, dest) / 1000).toFixed(1)
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const formatDate = (date) => moment(date).format("DD/MM/YYYY")

export const applyDiscount = (price, promo) => {
  if (isEmpty(promo) || !promo.valid) {
    return price
  } else {
    const discountedPrice = promo.type === 'percent' ? price - (price * promo.amount / 100.) : price - promo.amount
    const priceCheckZero = discountedPrice > 0 ? discountedPrice : 0
    return priceCheckZero
  }
}
