import React from 'react'
import {Container, Row, Col, Form, Button, DropdownButton, Dropdown} from 'react-bootstrap'
import {darkGrey, black, lightGrey, appRed, appBlue, appGold} from "../../styles/color"
import NavBar from "../../component/navBar"
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import moment from 'moment'
import TimePicker from 'rc-time-picker';
import '../../styles/rc-time-picker/index.css'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {next} from "../../index"

class CreditCardScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      number: '',
      name: '',
      expiry: '',
      cvv: '',
      focused: false,
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  componentDidMount = () => {
    console.log(this.props)
  }

  handleExpiryChange = (event) => {
    const expiry = event.target.value
    if (expiry.length === 2) {
      if (expiry.length > this.state.expiry.length) {
        this.setState({expiry: `${expiry}/`})
      } else {
        this.setState({expiry: this.state.expiry.substring(0, 1)})
      }
    } else {
      this.setState({expiry})
    }
  }

  _renderCustomerDetail = () => (
    <Form.Group>
      <Form.Control onChange={(event) => this.setState({number: event.target.value})} maxLength={16} type="text" placeholder="Card Number" size="sm" />
      <Form.Control onChange={(event) => this.setState({name: event.target.value})} style={{marginTop: '8px'}} type="text" placeholder="Name" size="sm" />
      <Form.Row style={{marginTop: '8px'}}>
        <Col>
          <Form.Control maxLength={5} value={this.state.expiry} onChange={(event) => this.handleExpiryChange(event)} type="text" placeholder="MM/YY" size="sm" />
        </Col>
        <Col>
          <Form.Control maxLength={4} onChange={(event) => this.setState({cvv: event.target.value})} type="password" placeholder="CVV" size="sm" />
        </Col>
      </Form.Row>
    </Form.Group>
  )

  render() {
    return (
      <Container style={styles.container}>
        <NavBar props={this.props} title={'ชำระเงิน'}/>
        <Row style={{padding: '8px', paddingTop: '15px'}}>
          <Cards
            number={this.state.number}
            name={this.state.name}
            expiry={this.state.expiry}
            cvc={this.state.cvc}
            focused={this.state.focused}
          />
        </Row>
        <Row style={{padding: '8px', paddingTop: '20px'}}>
          <Col xs={12} style={{border: `1px solid ${lightGrey}`, borderRadius: '5px', width: this.props.width, paddingTop: '10px', paddingBottom: '10px'}}>
            <Form>
              {this._renderCustomerDetail()}
            </Form>
          </Col>
        </Row>
        <Button style={{marginTop: '20px', marginBottom: '8px'}} variant={'success'} block>ยืนยัน</Button>
      </Container>
    )
  }
}

const styles = {
  summaryText: {
    fontSize: '12px',
  },
  summaryTimeText: {
    fontSize: 12,
    display: 'inline',
    marginLeft: '15px',
  },
  summaryCarText: {
    fontSize: 12,
    display: 'inline',
    marginLeft: '10px',
  },
  priceText: {
    textAlign: 'right',
    fontWeight: 'bold',
  },
  reservationSummaryContainer: {
    backgroundColor: 'white',
    marginLeft: '5%',
    width: '90%',
    border: `1px solid ${lightGrey}`,
    borderRadius: 5,
  },
  carSummaryContainer: {
    backgroundColor: 'white',
    marginLeft: '5%',
    width: '90%',
    border: `1px solid ${lightGrey}`,
    borderRadius: 5,
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  inputRowUpper: {
    paddingLeft: '5px',
    paddingTop: '12px',
    alignItems: 'center',
  },
  inputRowLower: {
    paddingLeft: '5px',
    alignItems: 'center',
  },
}

export default CreditCardScreen
