import React from 'react'
import {GoogleMap, Marker, withGoogleMap, DirectionsRenderer} from 'react-google-maps'
import {Col, Container, Row, Button} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {withRouter} from "react-router-dom"
import {appBlue, appGold, appRed, black, darkGrey, lightGrey} from "../styles/color"
import {next} from "../index"
import {setPlace} from "./helper"
import {styles} from "../styles/map"
import {DirectionInputComponent, SubmitComponent} from "../component/map"

const liff = window.liff

const defaultMapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
}

class MapView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPosition: {},
      driverPosition: {},
    }
  }

  static defaultProps = {
    height: window.innerHeight
  }

  componentDidMount = async () => {
    this._getCurrentPosition();
    // const pickup = await JSON.parse(localStorage.getItem('pickup'));
    // const dest = await JSON.parse(localStorage.getItem('dest'));
    // console.log(pickup, dest)
    this.interval = setInterval(() => this._getDriverPosition(), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  MapComponent = withGoogleMap(props =>
    <GoogleMap
      ref={(map) => this._map = map}
      options={defaultMapOptions}
      defaultZoom={16}
      center={this.state.center}
    >
      {props.children}
    </GoogleMap>
  );

  DriverInfoComponent = () => (
    <Container style={{position: 'absolute', bottom: '20px'}}>
      <Container style={styles.directionInputContainer}>
        <Row style={{alignItems: 'center'}}>
          <Col xs={1}><FontAwesomeIcon icon="user" color={appBlue}/></Col>
          <Col style={{marginTop: 10}} xs={9}>
            <p style={styles.text}>Example Driver Name<br/>081-111-1111</p>
          </Col>
          <Col xs={1}><FontAwesomeIcon icon="phone" color={appBlue}/></Col>
        </Row>
      </Container>
    </Container>
  )

  render() {
    return (
      <div>
        <this.MapComponent
          loadingElement={<div style={{height: `100%`}}/>}
          containerElement={<div style={{height: `${this.props.height}px`}}/>}
          mapElement={<div style={{height: `100%`}}/>}
        >
          <Marker
            icon={{
              url: require('../assets/location-pin/current-loc.png'),
              scaledSize: new window.google.maps.Size(40, 40),
            }}
            position={this.state.currentPosition}
          />
          <Marker
            icon={{
              url: require('../assets/location-pin/pickup.png'),
              scaledSize: new window.google.maps.Size(32, 50),
            }}
            position={this.state.pickupPosition}/>
          <Marker
            icon={{
              url: require('../assets/location-pin/dest.png'),
              scaledSize: new window.google.maps.Size(35, 50),
            }}
            position={this.state.destPosition}/>
          {this.DriverInfoComponent()}
        </this.MapComponent>
      </div>
    )
  }

  _getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          currentPosition: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
      })
    } else {
      alert("Geolocation is not supported by your browser")
    }
  };

  _getDriverPosition = () => {
    // get driver position api
  };
}

export default MapView
