import {Alert, Button} from "react-bootstrap";
import React from "react"

export const AlertSuccessBox = ({alertSuccessCallback}) => (
  <Alert variant='success'>
    <Alert.Heading>Done!</Alert.Heading>
    <p>
      ทำการจองสำเร็จ
    </p>
    <hr />
    <div className="d-flex justify-content-end">
      <Button onClick={() => alertSuccessCallback()} variant="outline-success">
        Close
      </Button>
    </div>
  </Alert>
)

export const AlertSlipBox = ({alertSuccessCallback}) => (
  <Alert variant='success'>
    <Alert.Heading>Done!</Alert.Heading>
    <p>
      Upload สำเร็จ ระบบจะทำการตรวจสอบใบเสร็จของท่าน
    </p>
    <hr />
    <div className="d-flex justify-content-end">
      <Button onClick={() => alertSuccessCallback()} variant="outline-success">
        Close
      </Button>
    </div>
  </Alert>
)

export const AlertBox = ({alertFailCallback}) => (
  <Alert variant='danger'>
    <Alert.Heading>Error!</Alert.Heading>
    <p>
      ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง
    </p>
    <hr />
    <div className="d-flex justify-content-end">
      <Button onClick={() => alertFailCallback()} variant="outline-danger">
        Close
      </Button>
    </div>
  </Alert>
)
