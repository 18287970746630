/* eslint-disable no-undef */

import React from 'react'
import {GoogleMap, Marker, withGoogleMap} from 'react-google-maps'
import {Col, Container, Row, Button} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {withRouter} from "react-router-dom"
import {getDistance} from 'geolib'
import {appBlue, appGold, appRed, black, darkGrey, lightGrey} from "../../styles/color"
import {next} from "../../index"
import {getDateTime, setPlace, toDateString} from "../helper"
import {styles} from "../../styles/map"
import {DirectionInputComponent, SubmitComponent} from "../../component/map"
import {signup} from "../../api/auth"

const liff = window.liff

const defaultMapOptions = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
}

class MapView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPosition: {},
      center: {},
      pickup: null,
      pickupPosition: {},
      dest: null,
      destPosition: {},
      distance: -1,
    }
  }

  static defaultProps = {
    height: window.innerHeight
  }

  componentDidMount = async () => {
    // localStorage.clear()
    const pickup = await JSON.parse(localStorage.getItem('pickup'))
    const dest = await JSON.parse(localStorage.getItem('dest'))
    this._getLocation(pickup, dest)
    this._fillLocationBox({pickup, dest})
  }

  MapComponent = withGoogleMap(props =>
    <GoogleMap
      ref={(map) => this._map = map}
      options={defaultMapOptions}
      defaultZoom={16}
      center={this.state.center}
      onCenterChanged={() => this._updateCenter()}
    >
      {props.children}
    </GoogleMap>
  )

  render() {
    return (
      <div>
        <this.MapComponent
          loadingElement={<div style={{height: `100%`}}/>}
          containerElement={<div style={{height: `${this.props.height}px`}}/>}
          mapElement={<div style={{height: `100%`}}/>}
        >
          <Marker
            icon={{
              url: require('../../assets/location-pin/current-loc.png'),
              scaledSize: {width: 40, height: 40},
            }}
            position={this.state.currentPosition}
          />
          {/*{CenterMarker(this.state.pickup, this.state.center)}*/}
          <Marker
            icon={{
              url: require('../../assets/location-pin/pickup.png'),
              scaledSize: {width: 32, height: 50},
            }}
            position={this.state.pickupPosition}/>
          <Marker
            icon={{
              url: require('../../assets/location-pin/dest.png'),
              scaledSize: {width: 35, height: 50},
            }}
            position={this.state.destPosition}/>
          {DirectionInputComponent({
            type: 'seniorlimo',
            props: this.props,
            state: this.state,
            currentPosition: this.state.currentPosition,
            pickup: this.state.pickup,
            dest: this.state.dest,
            cancel: () => this.setState({pickup: null})
          })}
        </this.MapComponent>
      </div>
    )
  }

  _getLocation = (pickup, dest) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (pickup !== null || dest !== null) {
          this.setState({
            currentPosition: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          })
        } else {
          this.setState({
            currentPosition: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          })
        }
      })
    } else {
      alert("Geolocation is not supported by your browser")
    }
  }

  _updateCenter = (selectLocation) => {
    if (selectLocation) {
      this.setState({center: selectLocation})
    }
    // else {
      // from moving the map
      // const mapCenter = this._map.getCenter()
      // if (this.state.pickup !== null && this.state.pickup.name !== 'Custom position') {
      //   this.setState({
      //     center: mapCenter,
      //   })
      // } else {
      //   const pickup = {
      //     name: 'Custom position',
      //     geometry: {
      //       location: {
      //         lat: mapCenter.lat(),
      //         lng: mapCenter.lng(),
      //       }
      //     }
      //   }
      //   this.setState({
      //     center: mapCenter,
      //     pickup,
      //     pickupPosition: {
      //       lat: mapCenter.lat(),
      //       lng: mapCenter.lng(),
      //     }
      //   })
      //   setPlace({
      //     type: 'pickup',
      //     place: pickup,
      //   })
      // }
    // }
  }

  _fillLocationBox = ({pickup, dest}) => {
    const pickupPosition = pickup ? pickup.geometry.location : null
    const destPosition = dest ? dest.geometry.location : null
    this.setState({pickup, dest, pickupPosition, destPosition})
    if (pickupPosition !== null || destPosition !== null) {
      if (destPosition) {
        this._updateCenter(destPosition)
      } else {
        this._updateCenter(pickupPosition)
      }
    }
  }

  // _swapPlace = () => {
  //   const pickup = this.state.pickup
  //   const pickupPosition = this.state.pickupPosition
  //   const dest = this.state.dest
  //   const destPosition = this.state.destPosition
  //   this.setState({
  //     pickup: dest,
  //     pickupPosition: destPosition,
  //     dest: pickup,
  //     destPosition: pickupPosition,
  //   })
  //   setPlace({type: 'pickup', place: dest})
  //   setPlace({type: 'dest', place: pickup})
  // }
}

MapView = withRouter(MapView)
export default MapView
