import {appBlue, black, darkGrey, lightGrey} from "./color"

export const styles = {
  directionInputContainer: {
    backgroundColor: 'white',
    marginLeft: '5%',
    width: '90%',
    border: `1px solid ${lightGrey}`,
    borderRadius: 5,
  },
  submitContainer: {
    backgroundColor: appBlue,
    marginLeft: '5%',
    width: '90%',
    border: `1px solid transparent`,
    borderRadius: 5,
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  inputRowUpper: {
    paddingLeft: '5px',
    paddingTop: '8px',
    alignItems: 'center',
  },
  inputRowLower: {
    paddingLeft: '5px',
    alignItems: 'center',
  },
  placeholder: {
    fontSize: 13,
    color: darkGrey,
    backgroundColor: '#FFF',
    borderWidth: '0px',
  },
  text: {
    fontSize: 13,
    color: black,
    backgroundColor: '#FFF',
    borderWidth: '0px',
    textAlign: 'left',
  },
  submitBoxText: {
    color: 'white',
    fontSize: 12,
    display: 'inline',
    marginLeft: '6px',
  },
  distanceText: {
    fontSize: 10,
    fontWeight: 'bold',
    color: darkGrey,
  },
}
