import {getHeaders, URL} from "./constants";
import axios from "axios";

export const payOmise = async ({token, orderId}) => {
  const headers = await getHeaders()
  const data = {
    token,
    orderId,
  };
  return axios.post(`${URL}/line/paybyomise`, data, {headers})
};

export const submitSlip = async ({orderId, slip}) => {
  const headers = await getHeaders();
  const data = {
    orderId,
    slip: slip.split(';base64,')[1],
  };
  return axios.post(`${URL}/line/uploadslip`, data, {headers})
    .then(res => res)
    .catch(err => console.log(err))
};
