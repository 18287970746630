import {appBlue, appGold} from "../styles/color"
import {Col, Row} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React from "react"
import {back} from "../index"

class NavBar extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {props} = this.props
    return (
      <Row style={{height: window.innerHeight*0.1, backgroundColor: appBlue, alignItems: 'center'}}>
        <Col>
          <FontAwesomeIcon onClick={() => back(props)} icon="chevron-left" color={appGold} size={'lg'}/>
          <p style={styles.title}>{this.props.title}</p>
        </Col>
      </Row>
    )
  }
}

const styles = {
  title: {
    color: 'white',
    fontSize: 16,
    display: 'inline',
    marginLeft: '25px',
  }
}

export default NavBar
