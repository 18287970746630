import React from 'react'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {darkGrey, black, lightGrey, blue, appBlue} from "../../styles/color"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {next, back} from "../../index"
import {googlePlace} from "../../api/map"

class PlaceSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      place: '',
      address: '',
      currentPosition: {},
      result: [],
    }
  }

  static defaultProps = {
    height: window.innerHeight,
    width: window.innerWidth,
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <Container style={styles.container}>
        <Row style={{alignItems: 'center'}}>
          <Col xs={1}><FontAwesomeIcon onClick={() => back(this.props)} size={"lg"} icon="chevron-circle-left" color={darkGrey}/></Col>
          <Col>
            <Form.Control style={styles.textInput} type="text" placeholder="ค้นหา" value={this.state.place} onChange={(event) => this.handleChange(event.target.value)} />
          </Col>
          <Col xs={2}><FontAwesomeIcon onClick={() => next({props: this.props, pathname: '/select-location', params: this.props.location.params})} size={"lg"} icon="map-marked-alt" color={blue}/></Col>
        </Row>
        <Row>
          <Col xs={12} style={{border: `1px solid ${lightGrey}`, width: this.props.width, marginTop: '10px'}}>
            {this._renderResult()}
          </Col>
        </Row>
      </Container>
    )
  }

  _renderResult = () => {
    if (this.state.result) {
      return (
        this.state.result.map(item => {
          const place = item.structured_formatting.main_text
          return (
            <Row style={{borderBottom: `1px solid ${lightGrey}`, paddingTop: '10px', paddingBottom: '10px'}}>
              <Col xs={1}><FontAwesomeIcon icon="map-marker-alt" color={appBlue}/></Col>
              <Col xs={10}><Button onClick={() => this._placeSelect(item)} style={styles.textButton}>{place}</Button></Col>
            </Row>
          )
        })
      )
    } else {
      return (
        <Row style={{borderBottom: `1px solid ${lightGrey}`, paddingTop: '10px', paddingBottom: '10px'}}>
          <Col xs={12}><p style={{paddingTop: '10px', fontSize: '12px'}}>No Results</p></Col>
        </Row>
      )
    }
  }

  handleChange = async (place) => {
    this.setState({ place })
    const result = await googlePlace({place})
    this.setState({result})
  }

  _placeSelect = (item) => {
    const type = this.props.history.location.params.type
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({placeId: item.place_id}, (place, status) => {
      this.props.history.location.params.setPlace({type, place})
      back(this.props)
    });
  }
}

const styles = {
  container: {
    padding: '15px',
  },
  text: {
    fontSize: 12,
  },
  textInput: {
    color: black,
  },
  textButton: {
    fontSize: 12,
    color: black,
    backgroundColor: '#FFF',
    borderWidth: '0px',
    textAlign: 'left',
  },
}

export default PlaceSearch
